<template>
  <div>
    <h3 class="terms-header">Terms</h3>
    <h3 class="terms-header">1. Definitions.</h3>
    <p class="terms-paragraph">
      “Art” means any art, design, and drawings that may be associated with an
      NFT that you Own.
    </p>
    <p class="terms-paragraph">
      "NFT" means any blockchain-tracked, non-fungible token, such as those
      conforming to the ERC-721 standard.
    </p>
    <p class="terms-paragraph">
      “Own” means, with respect to an NFT, an NFT that you have purchased or
      otherwise rightfully acquired from a legitimate source, where proof of
      such purchase is recorded on the relevant blockchain.
    </p>
    <p class="terms-paragraph">
      “Extensions” means third party designs that: (i) are intended for use as
      extensions or overlays to the Art, (ii) do not modify the underlying Art,
      and (iii) can be removed at any time without affecting the underlying Art.
    </p>
    <p class="terms-paragraph">“Purchased NFT” means an NFT that you Own.</p>
    <p class="terms-paragraph">
      “Third Party IP” means any third party patent rights (including, without
      limitation, patent applications and disclosures), copyrights, trade
      secrets, trademarks, know-how or any other intellectual property rights
      recognized in any country or jurisdiction in the world.
    </p>
    <h3 class="terms-header">2. Ownership.</h3>
    <p class="terms-paragraph">
      You acknowledge and agree that Creator (or, as applicable, its licensors)
      owns all legal right, title and interest in and to the Art, and all
      intellectual property rights therein. The rights that you have in and to
      the Art are limited to those described in this License. Creator reserves
      all rights in and to the Art not expressly granted to you in this License.
    </p>
    <h3 class="terms-header">3. License.</h3>
    <p class="terms-paragraph">
      a. General Use. Subject to your continued compliance with the terms of
      this License, Creator grants you a worldwide, non-exclusive,
      non-transferable, royalty-free license to use, copy, and display the Art
      for your Purchased NFTs, along with any Extensions that you choose to
      create or use, solely for the following purposes: (i) for your own
      personal, non-commercial use; (ii) as part of a marketplace that permits
      the purchase and sale of your NFTs, provided that the marketplace
      cryptographically verifies each NFT owner’s rights to display the Art for
      their Purchased NFTs to ensure that only the actual owner can display the
      Art; or (iii) as part of a third party website or application that permits
      the inclusion, involvement, or participation of your NFTs, provided that
      the website/application cryptographically verifies each NFT owner’s rights
      to display the Art for their Purchased NFTs to ensure that only the actual
      owner can display the Art, and provided that the Art is no longer visible
      once the owner of the Purchased NFT leaves the website/application.
    </p>
    <p class="terms-paragraph">
      b. Commercial Use. Subject to your continued compliance with the terms of
      this License, Creator grants you a limited, worldwide, non-exclusive,
      non-transferable license to use, copy, and display the Art for your
      Purchased NFTs for the purpose of commercializing your own merchandise
      that includes, contains, or consists of the Art for your Purchased NFTs
      (“Commercial Use”), provided that such Commercial Use does not result in
      you earning more than One Hundred Thousand Dollars ($100,000) in gross
      revenue each year. For the sake of clarity, nothing in this Section 3.b
      will be deemed to restrict you from (i) owning or operating a marketplace
      that permits the use and sale of NFTs generally, provided that the
      marketplace cryptographically verifies each NFT owner’s rights to display
      the Art for their Purchased NFTs to ensure that only the actual owner can
      display the Art; (ii) owning or operating a third party website or
      application that permits the inclusion, involvement, or participation of
      NFTs generally, provided that the third party website or application
      cryptographically verifies each NFT owner’s rights to display the Art for
      their Purchased NFTs to ensure that only the actual owner can display the
      Art, and provided that the Art is no longer visible once the owner of the
    </p>
    <p class="terms-paragraph">
      Purchased NFT leaves the website/application; or (iii) earning revenue
      from any of the foregoing, even where such revenue is in excess of
      $100,000 per year.
    </p>
    <h3 class="terms-header">4. Restrictions.</h3>
    <p class="terms-paragraph">
      You agree that you may not, nor permit any third party to do or attempt to
      do any of the foregoing without Creator’s express prior written consent in
      each case: (i) modify the Art for your Purchased NFT in any way,
      including, without limitation, the shapes, designs, drawings, attributes,
      or color schemes (your use of Extensions will not constitute a prohibited
      modification hereunder); (ii) use the Art for your Purchased NFTs to
      advertise, market, or sell any third party product or service; (iii) use
      the Art for your Purchased NFTs in connection with images, videos, or
      other forms of media that depict hatred, intolerance, violence, cruelty,
      or anything else that could reasonably be found to constitute hate speech
      or otherwise infringe upon the rights of others; (iv) use the Art for your
      Purchased NFTs in movies, videos, or any other forms of media, except to
      the limited extent that such use is expressly permitted in Section 3(b)
      above or solely for your own personal, non-commercial use; (v) sell,
      distribute for commercial gain (including, without limitation, giving away
      in the hopes of eventual commercial gain), or otherwise commercialize
      merchandise that includes, contains, or consists of the Art for your
      Purchased NFTs, except as expressly permitted in Section 3(b) above; (vi)
      attempt to trademark, copyright, or otherwise acquire additional
      intellectual property rights in or to the Art for your Purchased NFTs; or
      (vii) otherwise utilize the Art for your Purchased NFTs for your or any
      third party’s commercial benefit. To the extent that Art associated with
      your Purchased NFTs contains Third Party IP (e.g., licensed intellectual
      property from a celebrity, athlete, or other public figure), you
      understand and agree as follows: (w) that you will not have the right to
      use such Third Party IP in any way except as incorporated in the Art, and
      subject to the license and restrictions contained herein; (x) that the
      Commercial Use license in Section 3(b) above will not apply; (y) that,
      depending on the nature of the license granted from the owner of the Third
      Party IP, Creator may need to pass through additional restrictions on your
      ability to use the Art; and (z) to the extent that Creator informs you of
      such additional restrictions in writing (email is permissible), you will
      be responsible for complying with all such restrictions from the date that
      you receive the notice, and that failure to do so will be deemed a breach
      of this license. The restriction in Section 4 will survive the expiration
      or termination of this License.
    </p>
    <h3 class="terms-header">5. Terms of License.</h3>
    <p class="terms-paragraph">
      The license granted in Section 3 above applies only to the extent that you
      continue to Own the applicable Purchased NFT. If at any time you sell,
      trade, donate, give away, transfer, or otherwise dispose of your Purchased
      NFT for any reason, the license granted in Section 3 will immediately
      expire with respect to those NFTs without the requirement of notice, and
      you will have no further rights in or to the Art for those NFTs. If you
      exceed the $100,000 limitation on annual gross revenue set forth in
      Section 3.b above, you will be in breach of this License, and must send an
      email to 6K1Q LLC at bigjizz69@protonmail.com within fifteen (15) days,
      with the phrase “NFT License - Commercial Use” in the subject line,
      requesting a discussion with Creator regarding entering into a broader
      license agreement or obtaining an exemption (which may be granted or
      withheld in Creator’s sole and absolute discretion). If you exceed the
      scope of the license grant in Section 3.b without entering into a broader
      license agreement with or obtaining an exemption from Creator, you
      acknowledge and agree that: (i) you are in breach of this License; (ii) in
      addition to any remedies that may be available to Creator at law or in
      equity, the Creator may immediately terminate this License, without the
      requirement of notice; and (iii) you will be responsible to reimburse
      Creator for any costs and expenses incurred by Creator during the course
      of enforcing the terms of this License against you.
    </p>
  </div>
</template>
<style scoped>
.terms-header {
  color: #b296cd;
  margin: 30px 0;
}

.terms-paragraph {
  color: white;
}
</style>
